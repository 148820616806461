import {
  Member as MemberNg,
  Profile,
} from '@wix/ambassador-members-v1-member/types';
import { Badge } from '@wix/ambassador-badges-v2-badge/types';
import {
  Origin,
  SocialTypeType as SocialType,
  Type as FieldType,
} from '@wix/ambassador-members-v1-custom-field/types';
import { RichContent } from '@wix/ambassador-members-about-v2-member-about/types';
import { Post } from '@wix/ambassador-blog-v3-post/types';
import { DraftContent } from '@wix/ricos-editor';

export {
  Type as FieldType,
  SocialTypeType as SocialType,
} from '@wix/ambassador-members-v1-custom-field/types';

export enum Role {
  Admin = 'admin',
  BlogEditor = 'blog_editor',
  BlogWriter = 'blog_writer',
}

export interface ActivityCounters {
  likesReceived: number;
  commentsReceived: number;
  topCommentsCount: number;
}

export type MemberAbout = Partial<RichContent> & Partial<DraftContent>;

export interface Field {
  id: string;
  type: FieldType;
  name: string;
  key: string;
  origin: Origin;
  value: (string | number)[] | null;
}

export interface SocialLink {
  id: string;
  name: string;
  socialType: SocialType;
  href: string | number | null;
}

export interface CustomProfile {
  fields: Field[];
  socialLinks: SocialLink[];
}

export interface BlogPost {
  id: NonNullable<Post['id']>;
  url: NonNullable<Post['url']>;
  title: NonNullable<Post['title']>;
  excerpt: NonNullable<Post['excerpt']>;
  coverMedia: NonNullable<Post['coverMedia']>;
  minutesToRead: NonNullable<Post['minutesToRead']>;
  postCountInfo: NonNullable<Post['postCountInfo']>;
  firstPublishedDate: string;
}

export interface Member {
  id: MemberNg['id'];
  slug: Profile['slug'];
  roles: Role[];
  numbers: ActivityCounters;
  about: MemberAbout;
  badges: Badge[];
  customProfile: CustomProfile;
  blogPosts: BlogPost[];
  createdDate: string | null;
}
